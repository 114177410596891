<template>
    <div class="app-container" v-loading="loading">

        <el-row :gutter="20" >
            <el-col :span="8" class="activity-panel">
                <activity-panel v-show="!loading"
                        :activity="activity"
                        class="content"
                        @validate="validate"
                        @update="updateActivityAfterDelay"
                        @delete="showDeleteDialog = true"
                        @changed="activityChanged"
                        :updating="updating"
                        :deleting="deleting"
                />
            </el-col>
            <el-col :span="16" class="activity-slides">
                <activity-slides v-show="!loading"
                        :activity="activity"
                        :slides="slides"
                        :descValid="desc_valid"
                        @descriptionChange="descriptionChange"
                        @updateSlides="updateSlides"
                />
            </el-col>
        </el-row>

        <el-dialog class="deleteActivityDialog" :visible.sync="showDeleteDialog" title="Delete activity">
          Are you sure you want to delete this activity? This action cannot be undone.
          <div class="footer">
            <el-button @click="showDeleteDialog = false">Cancel</el-button>
            <el-button type="danger" @click="deleteActivity">Delete</el-button>
          </div>
        </el-dialog>

    </div>
</template>

<script>
    import ActivityPanel from '@/views/activities/ActivityPanel';
    import ActivitySlides from '@/views/activities/ActivitySlides';
    import axios from 'axios';

    export default {
        name: 'activity-profile',
        data() {
            return {
                activity: {
                  id: null,
                  title: null,
                  image_url: null,
                  frequency_id: null,
                  duration: null,
                  difficulty: null,
                  evidence_quality: null,
                  core_items: [],
                  resources: [],
                  endcard_title: null,
                  endcard_message: null,
                  description: null,
                },
                slides: [],
                deletedSlides: [],
                loading: true,
                updating: false,
                deleting: false,

                desc_valid: null,

                changes: false,

                showDeleteDialog: false,
            };
        },
        components: {
            ActivityPanel,
            ActivitySlides,
        },
        methods: {
          showChangeNotice() {
            this.changes = true;
            this.$bus.$emit('navbar-notice', 'You have unsaved changes pending for this activity.');
          },
          hideChangeNotice() {
            this.changes = false;
            this.$bus.$emit('navbar-notice', null);
          },
          async getActivity() {
            this.loading = true;

            if(this.activity.id) {

              // get activity:
              const token = await this.$auth.getTokenSilently();

              axios.get(`${process.env.VUE_APP_BASE_URI}/activities/${this.activity.id}`,
                {
                  headers: {
                    //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                    'Authorization': `Bearer ${token}`
                  }
                }
              )
              .then(response => {
                  if(response.data.data) {
                    this.activity = {...this.activity, ...response.data.data };

                    // convert core_items to ID Array
                    this.activity.core_items = this.activity.core_items.map(item => {
                      return item.coreitem_id;
                    });
                    // convert resources to ID Array
                    this.activity.resources = this.activity.resources.map(item => {
                      return item.resource_id;
                    });
                  }

                  // get slides:
                  axios.get(`${process.env.VUE_APP_BASE_URI}/activities/${this.activity.id}/slides`,
                    {
                      headers: {
                        //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                        'Authorization': `Bearer ${token}`
                      }
                    }
                  )
                  .then(response => {
                      if(response.data.data) {
                        this.slides = [...this.slides, ...response.data.data.slides];
                      }
                      this.loading = false;
                  })
                  .catch((error) => {
                      this.loading = false;
                      console.log("API error: get slides",error,error.response);
                  });


              })
              .catch((error) => {
                  this.loading = false;
                  console.log("API error: get activity",error,error.response);
              });

            }
            else {
              this.loading = false;
            }
            
          },

          validate() {
            // validate any other items
            if(!this.activity.description) {
              this.desc_valid = false;
            }
            else {
              this.desc_valid = true;
            }
          },

          updateActivityAfterDelay(activityData) {
            // can't guarantee rich editor has sent updated content for at least 200ms after blur
            setTimeout(() => {
              this.updateActivity(activityData);
            },300);
          },

          async updateActivity(activityData) {
            // main form is submitted and valid; check description as well
            if(!this.activity.description) {
              this.desc_valid = false;
              return;
            }
            else {
              const token = await this.$auth.getTokenSilently();

              this.desc_valid = true;

              this.updating = true;
              
              // merge existing & updated activity data
              var updateActivity = { ...this.activity, ...activityData };

              // remove image_url from submitted data, use supplied file if present
              delete updateActivity.image_url;

              // send changes to endpoint

              var formData = new FormData();
              for(const prop in updateActivity) {
                const propValue = updateActivity[prop];
                if(Array.isArray(propValue)) {
                  for (var i=0; i < propValue.length; i++) {
                    formData.append(`${prop}[]`, propValue[i]);
                  }
                }
                else {
                  formData.append(prop,updateActivity[prop]);
                }
              }
              var config = {
                url:`${process.env.VUE_APP_BASE_URI}/activities`,
                headers: {
                  'Content-Type': 'multipart/form-data',
                  //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                  'Authorization': `Bearer ${token}`
                },
                data: formData,
                method:'post',
              }

              if(updateActivity.id) {
                config.url += "/" + updateActivity.id;
                formData.append('_method','put');
              }

              axios(config)
              .then(response => {
                  this.activity = { ...updateActivity };

                  // set ID if new activity was created
                  if(!this.activity.id) {
                    this.activity.id = response.data.data.id;
                    this.saveSlides(this.activity.id);
                  }
                  else {
                    this.saveSlides();
                  }
              })
              .catch((error) => {
                  //this.activity = null;
                  this.updating = false;
                  console.log("API error: update activity",error,error.response);
                  if(error.response.status == 422) {
                    this.$message.error(
                        'Unable to upload activity image. Please check your image meets the upload requirements.',
                    );
                  }
              });

            }
          },
          async deleteActivity() {
            const token = await this.$auth.getTokenSilently();

            axios.delete(`${process.env.VUE_APP_BASE_URI}/activities/${this.activity.id}`,
              {
                headers: {
                  //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                  'Authorization': `Bearer ${token}`
                }
              }
            )
            .then(response => {
                console.log("deleted activity",response);
                this.$router.push(`/activities`)
            })
            .catch((error) => {
                console.log("API error: delete activity",error,error.response);
            });
          },
          activityChanged(changed) {
            if(changed) {
              this.showChangeNotice();
            }
            else this.hideChangeNotice();
          },

          descriptionChange(description) {
            if(description) this.desc_valid = true;
            else this.desc_valid = false;
            this.showChangeNotice();
            this.activity.description = description;
          },
          updateSlides(slides,deleted) {
            this.showChangeNotice();
            this.slides = slides;
            if(deleted) {
              this.deletedSlides = deleted;
            }
          },

          async saveSlides(activity_id) {
            var fail = false;
            // delete all removed slides
            const token = await this.$auth.getTokenSilently();

            for(var i=0;i<this.deletedSlides.length;i++) {
              var slide = this.deletedSlides[i];

              await axios.delete(`${process.env.VUE_APP_BASE_URI}/activities/${this.activity.id}/slides/${slide.id}`,
                {
                  headers: {
                    //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                    'Authorization': `Bearer ${token}`
                  }
                }
              )
              .then(response => {
                  console.log("deleted slide",slide,response);
              })
              .catch((error) => {
                  console.log("API error: delete slide",error,error.response);
              });
            }
            this.deletedSlides = [];

            // update all modified slides
            for(i=0;i<this.slides.length;i++) {
              slide = this.slides[i];

              if(slide.updated) {
                // don't update url directly if file is supplied
                if(slide.file) { delete slide.file_url; }

                var formData = new FormData();
                for(const prop in slide) {
                  const propValue = slide[prop];
                  if(Array.isArray(propValue)) {
                    for (var j=0; i < propValue.length; j++) {
                      formData.append(`${prop}[]`, propValue[j]);
                    }
                  }
                  else {
                    formData.append(prop,slide[prop]);
                  }
                }
                var config = {
                  url:`${process.env.VUE_APP_BASE_URI}/activities/${this.activity.id}/slides`,
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                    'Authorization': `Bearer ${token}`
                  },
                  data: formData,
                  method:'post',
                }

                if(slide.id) {
                  config.url += "/" + slide.id;
                  formData.append('_method','put');
                }

                await axios(config)
                .then(response => {
                    // set ID if new slide was created
                    if(!slide.id) { slide.id = response.data.data.id }
                })
                .catch((error) => {
                    console.log("API error: save slide",error,error.response);
                    if(error.response.status == 422) {
                      this.$message.error(
                          'Unable to upload slide image. Please check your image meets the upload requirements.',
                      );
                      fail = true;
                    }

                });
              }

            }

            // update slide order
            const order = { ids: this.slides.map(slide => { return slide.id; }) };
            await axios.put(`${process.env.VUE_APP_BASE_URI}/activities/${this.activity.id}/slides/order`,
              order,
              {
                headers: {
                  //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                  'Authorization': `Bearer ${token}`
                }
              }
            )
            .then(response => {
                console.log("updated order",order,response);
            })
            .catch((error) => {
                console.log("API error: update order",error,error.response);
            });

            this.updating = false;

            if(!fail) {
              this.hideChangeNotice();

              this.$router.push(`/activities`)
            }

          }
        },
        mounted() {
          this.activity.id = this.$route.params.activity_id;

          this.getActivity();

        },

        beforeRouteLeave(to,from,next) {
          if(this.changes) {
            const answer = window.confirm('Are you sure you want to leave? Your unsaved changes will be lost.');
            if(answer) {
              this.hideChangeNotice()
              next();
            }
            else {
              next(false);
            }
          }
          else {
            this.hideChangeNotice();
            next();
          }
        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .app-container {
        padding: 0;
        min-height: calc(100vh - 50px);
        overflow: hidden;

        .el-row {
            margin: 0 !important;
        }
    }

    .activity-panel {
        padding: 10px 40px 50px 40px !important;
        box-sizing: border-box;
        background-color: white;
        height: calc(100vh - 50px);
        overflow: scroll;
    }

    .activity-slides {
        padding: 10px 40px !important;
        box-sizing: border-box;
        background-color: #F0F2F5;
        height: calc(100vh - 50px);
        overflow: scroll;
    }

    .deleteActivityDialog {
      .footer {
        margin-top: 20px;
        display: flex;
        > * {
          flex-grow: 1;
        }
      }
    }

</style>

<style>
  .deleteActivityDialog .el-dialog {
    width: 640px;
    max-width: calc(100% - 40px);
  }
</style>
