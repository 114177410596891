var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "activity-panel", attrs: { span: 8 } },
            [
              _c("activity-panel", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "content",
                attrs: {
                  activity: _vm.activity,
                  updating: _vm.updating,
                  deleting: _vm.deleting,
                },
                on: {
                  validate: _vm.validate,
                  update: _vm.updateActivityAfterDelay,
                  delete: function ($event) {
                    _vm.showDeleteDialog = true
                  },
                  changed: _vm.activityChanged,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "activity-slides", attrs: { span: 16 } },
            [
              _c("activity-slides", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                attrs: {
                  activity: _vm.activity,
                  slides: _vm.slides,
                  descValid: _vm.desc_valid,
                },
                on: {
                  descriptionChange: _vm.descriptionChange,
                  updateSlides: _vm.updateSlides,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "deleteActivityDialog",
          attrs: { visible: _vm.showDeleteDialog, title: "Delete activity" },
          on: {
            "update:visible": function ($event) {
              _vm.showDeleteDialog = $event
            },
          },
        },
        [
          _vm._v(
            " Are you sure you want to delete this activity? This action cannot be undone. "
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDeleteDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.deleteActivity },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }