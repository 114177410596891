var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.activity.id ? "Edit" : "Add") + " Activity"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.updating },
              on: { click: _vm.handleSubmit },
            },
            [
              _vm._v(
                " Save" + _vm._s(_vm.activityData.id ? " changes" : "") + " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            autoComplete: "on",
            model: _vm.activityData,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "title", label: "Title" } },
            [
              _c("el-input", {
                attrs: {
                  name: "title",
                  type: "text",
                  required: "",
                  autoComplete: "on",
                  placeholder: "Activity title",
                },
                model: {
                  value: _vm.activityData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.activityData, "title", $$v)
                  },
                  expression: "activityData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "subtitle", label: "Subtitle" } },
            [
              _c("el-input", {
                attrs: {
                  name: "subtitle",
                  type: "text",
                  required: "",
                  autoComplete: "on",
                  placeholder: "Activity subtitle",
                },
                model: {
                  value: _vm.activityData.subtitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.activityData, "subtitle", $$v)
                  },
                  expression: "activityData.subtitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "upload",
              attrs: { prop: "image_url", label: "Image" },
            },
            [
              _c("file-upload", {
                attrs: {
                  filetype: "image",
                  current: _vm.activityData.image_url,
                },
                on: {
                  select: _vm.handleImageSelected,
                  remove: _vm.handleImageRemoved,
                },
              }),
              _c("div", { staticClass: "footnote" }, [
                _vm._v("File size must be less than 2MB"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "frequency_id", label: "Frequency" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    name: "frequency_id",
                    placeholder: "Choose frequency",
                  },
                  model: {
                    value: _vm.activityData.frequency_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityData, "frequency_id", $$v)
                    },
                    expression: "activityData.frequency_id",
                  },
                },
                _vm._l(_vm.frequencies, function (frequency, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: frequency, value: index },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "duration", label: "Duration" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    name: "duration",
                    type: "number",
                    autoComplete: "on",
                    placeholder: "Duration",
                  },
                  model: {
                    value: _vm.activityData.duration,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityData, "duration", $$v)
                    },
                    expression: "activityData.duration",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("mins")])],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "difficulty", label: "Difficulty" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    name: "difficulty",
                    placeholder: "Choose difficulty",
                  },
                  model: {
                    value: _vm.activityData.difficulty,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityData, "difficulty", $$v)
                    },
                    expression: "activityData.difficulty",
                  },
                },
                _vm._l(_vm.difficulties, function (difficulty) {
                  return _c("el-option", {
                    key: difficulty.id,
                    attrs: { label: difficulty.label, value: difficulty.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "evidence",
              attrs: { prop: "evidence", label: "Evidence" },
            },
            [
              _c("el-rate", {
                attrs: { name: "evidence" },
                model: {
                  value: _vm.activityData.evidence_quality,
                  callback: function ($$v) {
                    _vm.$set(_vm.activityData, "evidence_quality", $$v)
                  },
                  expression: "activityData.evidence_quality",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "label-top",
              attrs: { label: "Applies to outcomes:", prop: "core_items" },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.activityData.core_items,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityData, "core_items", $$v)
                    },
                    expression: "activityData.core_items",
                  },
                },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.outcomeList, function (outcome) {
                      return _c(
                        "el-col",
                        { key: outcome.id, attrs: { span: 8 } },
                        [
                          _c("el-checkbox", { attrs: { label: outcome.id } }, [
                            _vm._v(_vm._s(outcome.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "label-top",
              attrs: { label: "Applies to resources:", prop: "resources" },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.activityData.resources,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityData, "resources", $$v)
                    },
                    expression: "activityData.resources",
                  },
                },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.resourceList, function (resource) {
                      return _c(
                        "el-col",
                        { key: resource.id, attrs: { span: 12 } },
                        [
                          _c("el-checkbox", { attrs: { label: resource.id } }, [
                            _vm._v(_vm._s(resource.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "endcard_title", label: "End card title" } },
            [
              _c("el-input", {
                attrs: {
                  name: "endcard_title",
                  type: "text",
                  autoComplete: "on",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.activityData.endcard_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.activityData, "endcard_title", $$v)
                  },
                  expression: "activityData.endcard_title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "endcard_message", label: "Message" } },
            [
              _c("el-input", {
                attrs: {
                  name: "endcard_message",
                  type: "textarea",
                  autoComplete: "on",
                  maxlength: "80",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.activityData.endcard_message,
                  callback: function ($$v) {
                    _vm.$set(_vm.activityData, "endcard_message", $$v)
                  },
                  expression: "activityData.endcard_message",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.updating },
              on: { click: _vm.handleSubmit },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.activityData.id ? "Update" : "Save") +
                  " activity "
              ),
            ]
          ),
          _c("hr"),
          _vm.activityData.id
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger", loading: _vm.deleting },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(" Delete activity ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }