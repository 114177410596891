var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Description")]),
      _c("rich-editor", {
        attrs: {
          content: _vm.activity.description,
          valid: _vm.descValid,
          "invalid-message": "Please enter a description for this activity",
        },
        on: { change: _vm.descriptionChange },
      }),
      _c(
        "div",
        { staticClass: "slides-header" },
        [
          _c("h4", [_vm._v("Activity slides")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addSlide } },
            [_vm._v("Add slide")]
          ),
        ],
        1
      ),
      _c(
        "el-table-draggable",
        { attrs: { handle: ".handle" }, on: { drop: _vm.dragSlide } },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.slideData } },
            [
              _c("el-table-column", {
                attrs: { width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", { staticClass: "handle el-icon-d-caret" }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Slide title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(" " + _vm._s(scope.row.title) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Slide type", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == "text"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  "disable-transitions": true,
                                  type: "info",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "handle el-icon-tickets",
                                }),
                                _vm._v(" Text "),
                              ]
                            )
                          : _vm._e(),
                        scope.row.type == "question"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  "disable-transitions": true,
                                  type: "info",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "handle el-icon-edit-outline",
                                }),
                                _vm._v(" Question "),
                              ]
                            )
                          : _vm._e(),
                        scope.row.type == "youtube"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  "disable-transitions": true,
                                  type: "info",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "handle el-icon-video-camera",
                                }),
                                _vm._v(" Video "),
                              ]
                            )
                          : _vm._e(),
                        scope.row.type == "image"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  "disable-transitions": true,
                                  type: "info",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                                _vm._v(" Image "),
                              ]
                            )
                          : _vm._e(),
                        scope.row.type == "upload"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  "disable-transitions": true,
                                  type: "info",
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-camera" }),
                                _vm._v(" Upload "),
                              ]
                            )
                          : _vm._e(),
                        scope.row.type == "pdf"
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  "disable-transitions": true,
                                  type: "info",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "handle el-icon-document",
                                }),
                                _vm._v(" PDF "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editSlide(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("slide-modal", {
        on: { update: _vm.handleSlideUpdate, delete: _vm.handleSlideDelete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }